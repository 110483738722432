import React, { useState, useEffect } from "react";
import { Button, Select, message, Modal } from "antd";
import axios from "axios";
import { BaseUrl, websocketConnectionUrl } from "../../../static/BaseUrl.js";

const Screen = () => {
  const [wsGlobal, setWsGlobal] = React.useState();
  const [charegrStatusesArray, setCharegrStatusArray] = useState();
  const [rawResponseData, setRawResponseData] = useState();
  const [responseMessage, setResponseMessage] = useState();

  const [selectedCharger, setSelectedCharger] = useState();
  const [triggerMessageModalOpen, setTriggerMessageModalOpen] = useState();

  const [selectedUser, setSelectedUser] = useState();
  const [selectedHours, setselectedHours] = useState();
  const [users, setUsers] = useState();

  const handleSendRequest = async (socket) => {
    try {
      const res = await axios.post(`${BaseUrl}/remote/start/request/by/admin`, {
        token: localStorage.getItem("token"),
        _id: JSON.parse(selectedUser)?._id,
        hours: selectedHours,
        chargerName: selectedCharger,
      });

      setTriggerMessageModalOpen(true);
      if (res?.data?.success) {
        setTriggerMessageModalOpen(false);
        message.success("Request sent charging starting shortly...");
      }
    } catch (error) {
      console.log(error);
    }
  };
  // /update/user/chargingperiod
  const getUsers = async () => {
    try {
      const res = await axios.post(`${BaseUrl}/get/users`, {
        token: localStorage.getItem("token"),
      });
      if (res?.data?.success) {
        setUsers(res?.data?.users);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    const charger = rawResponseData && rawResponseData.split("*")[1]?.trim();
    if (charger === selectedCharger) {
      if (
        rawResponseData &&
        rawResponseData.split("*")[2]?.split('"')[3] === "status" &&
        rawResponseData.split("*")[2]?.split('"')[1] === "StartTransaction"
      ) {
        const message =
          "Status : " + rawResponseData.split("*")[2]?.split('"')[5];
        setResponseMessage(message);
      }
    }
  }, [rawResponseData]);

  useEffect(() => {
    var ws = new WebSocket(websocketConnectionUrl);
    ws.onopen = () => {
      let sendData = {
        From: "BD8746G645VWU83",
      };
      ws.send(sendData);
      var refresh = true;
      setInterval(() => {
        refresh = true;
      }, 5000);

      ws.onmessage = (e) => {
        try {
          if (e.data.split("*")[0] === "StatusNotification ") {
            const arrayString = e.data.split("*")[1];
            const chargersMap = new Map();
            const array = JSON.parse(arrayString);
            setCharegrStatusArray(array);
            array.map((charger) => {
              return chargersMap.set(charger[0], charger[1]);
            });
            if (refresh === true) {
              refresh = false;
              // setChargerStatus(chargersMap);
            }
            // setChargerStatus(mapFromJSON);
          } else {
            setRawResponseData(e.data);
          }
        } catch (error) {}
      };

      ws.onclose = (e) => {
        ws.close();
      };
    };
    setWsGlobal(ws);
  }, []);

  return (
    <div>
      <Modal
        // title="Confirm"
        centered
        open={triggerMessageModalOpen}
        onCancel={() => {
          setTriggerMessageModalOpen(false);
          setResponseMessage("Waiting for response...");
        }}
        footer={[
          null,
          <Button
            onClick={() => {
              setTriggerMessageModalOpen(false);
              setResponseMessage("Waiting for response...");
            }}
          >
            Close
          </Button>,
          // <Button onClick={() => {setUnlockConnectorModalOpen(false) ; handleUnlockConnector()}}>Unlock</Button>,
        ]}
      >
        <p className=" font-bold text-center p-4 pt-8">Trigger Message</p>
        <p className="  text-center p-4 pt-8">
          {responseMessage ? responseMessage : "Waiting for response..."}
        </p>
        {/* <p className="font-bold text-center p-4">
          {selectedLocation && selectedLocation?.captiveCode}
        </p> */}
      </Modal>

      <p className="font-semibold text-xl mb-1">
        CMS Operation : Remote Start Transaction
      </p>
      <p className="text-slate-600">
        Central System can request a Charge Point to start a transaction by
        sending a RemoteStartTransaction.req. Upon receipt, the Charge Point
        SHALL reply with RemoteStartTransaction.conf and a status indicating
        whether it has accepted the request and will attempt to start a
        transaction. The effect of the RemoteStartTransaction.req message
        depends on the value of the AuthorizeRemoteTxRequests configuration key
        in the Charge Point.
      </p>

      <div className="mt-24 flex justify-center">
        <Select
          style={{ width: 200, marginLeft: 20, marginRight: 20 }}
          showSearch
          className="shadow-xl"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          placeholder={"Select Charger"}
          onChange={(value) => setSelectedCharger(value)}
        >
          {charegrStatusesArray &&
            charegrStatusesArray?.map((charger) => {
              return (
                <option
                  style={{ color: charger[1] === "Unavailable" ? "red" : "" }}
                  value={charger[0]}
                >
                  {charger[0]}
                </option>
              );
              // if (charger[1] !== "Unavailable") {
              //   return <option value={charger[0]}>{charger[0]}</option>;
              // }
            })}
        </Select>

        {selectedCharger ? (
          <Select
            style={{ width: 300, marginLeft: 20, marginRight: 20 }}
            className="shadow-xl"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            placeholder={"Select User"}
            onChange={(value) => setSelectedUser(value)}
          >
            {users &&
              users?.map((user) => {
                return (
                  <option value={JSON.stringify(user)}>
                    {"🧑🏻‍💼 " + user?.name + " 📞 " + user?.phone?.toLowerCase()}
                  </option>
                );
              })}
          </Select>
        ) : (
          ""
        )}

        {selectedUser ? (
          <Select
            style={{ width: 200, marginLeft: 20, marginRight: 20 }}
            className="shadow-xl"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            placeholder={"Select no. of hours"}
            onChange={(value) => setselectedHours(value)}
          >
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
            <option value={6}>6</option>
            <option value={7}>7</option>
            <option value={8}>8</option>
            <option value={9}>9</option>
            <option value={10}>10</option>
            <option value={11}>11</option>
            <option value={12}>12</option>
          </Select>
        ) : (
          ""
        )}
      </div>

      {selectedUser ? (
        <div className="flex justify-center mt-10">
          <Button
            style={{ backgroundColor: "blue", color: "white" }}
            className="shadow-xl"
            primary
            onClick={() => {
              handleSendRequest(wsGlobal);
            }}
          >
            Send Request
          </Button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Screen;
