import React, { useEffect, useState } from "react";
import { Avatar, Badge, Modal, Tabs } from "antd";
import { message } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Layout, Menu, Button, theme } from "antd";
import DashBoardScreen from "../Screens/AdminScreens/DashBoardScreen";
import LocationDetailsScreen from "../Screens/AdminScreens/LocationDetailsScreen";
import ChargerDetailsScreen from "../Screens/AdminScreens/ChargerDetailsScreen";
import UserDetailsScreen from "../Screens/AdminScreens/UserDetailsScreen";
import OngoingBookingsScreen from "../Screens/AdminScreens/OngoingBookingsScreen";
import CompletedBookingsScreen from "../Screens/AdminScreens/CompletedBookingsScreen";
import TransactionDetailsScreen from "../Screens/AdminScreens/TransactionDetailsScreen";
import FeedbackScreen from "../Screens/AdminScreens/FeedbacksScreen";
import RWADashboardScreen from "../Screens/RWASreens/RWADashboardScreen";
import RWAUsersDetails from "../Screens/RWASreens/RWAUsersDetailsScreen";
import RWACompletedBookingsScreen from "../Screens/RWASreens/RWACompletedBookingsScreen";
import RWAOngoingBookingsScreen from "../Screens/RWASreens/RWAOngoingScreeen";
import RWAadminDetails from "../Screens/AdminScreens/RWAadminDetails";
import AllChargersScreen from "../Screens/AdminScreens/AllChargersStatusPanelScreen";
import RFIDCardsScreen from "../Screens/AdminScreens/RFIDCardsScreen.js";
import RFIDCardsNewRequests from "../Screens/AdminScreens/RFIDCardsNewRequests.js";
import UsersConcernsScreen from "../Screens/AdminScreens/UsersConcernsScreen.js";
import PartnerQueryScreen from "../Screens/AdminScreens/PartnerQueryScreen.js";
import RWAPaymentsDetailsScreen from "../Screens/AdminScreens/RWAPaymentsDetails.js";
// CMS Operations
import CancelReservationScreen from "../Screens/AdminScreens/CMSOperationsScreens/CancelReservationScreen";
import ChangeAvailabilityScreen from "../Screens/AdminScreens/CMSOperationsScreens/ChangeAvailabilityScreen";
import ChangeConfigurationScreen from "../Screens/AdminScreens/CMSOperationsScreens/ChangeConfigurationScreen";
import ClearCacheScreen from "../Screens/AdminScreens/CMSOperationsScreens/ClearCacheScreen";
import ClearChargingProfile from "../Screens/AdminScreens/CMSOperationsScreens/ClearChargingProfileScreen";
import GetConfigurationScreen from "../Screens/AdminScreens/CMSOperationsScreens/GetConfigurationScreen";
import GetDiagnosticsScreen from "../Screens/AdminScreens/CMSOperationsScreens/GetDiagnosticsScreen";
import GetLocalListScreen from "../Screens/AdminScreens/CMSOperationsScreens/GetLocalListScreen";
import RemoteStartScreen from "../Screens/AdminScreens/CMSOperationsScreens/RemoteStartTransaction";
import RemoteStopScreen from "../Screens/AdminScreens/CMSOperationsScreens/RemoteStopTransactionScreen";
import ReserveNowScreen from "../Screens/AdminScreens/CMSOperationsScreens/ReserveNowScreen";
import ResetChargerScreen from "../Screens/AdminScreens/CMSOperationsScreens/ResetChargerScreen";
import SendLocalListScreen from "../Screens/AdminScreens/CMSOperationsScreens/SendLocalListScreen";
import SetChargingProfileScreen from "../Screens/AdminScreens/CMSOperationsScreens/SetChargingProfileScreen";
import TriggerMessageScreen from "../Screens/AdminScreens/CMSOperationsScreens/TriggerMessageScreen";
import UnlockConnectorScreen from "../Screens/AdminScreens/CMSOperationsScreens/UnlockConnectorScreen";
import UpdateFirmwareScreen from "../Screens/AdminScreens/CMSOperationsScreens/UpdateFirmwarescreen";
import DownLoadLogsScreen from "../Screens/AdminScreens/CMSOperationsScreens/DownloadLogsScreen";
import EvManufacturersScreen from "../Screens/AdminScreens/EvManufacturersScreen";
import EvCarsScreen from "../Screens/AdminScreens/EvCarsScreen";

import InvestorChargerDetailsScreen from "../Screens/InvestorScreens/InvestorChargerDetailsScreen";
import InvestorCompletedBookingsScreen from "../Screens/InvestorScreens/InvestorCompletedBookingsScreen";
import InvestorLocationDetailsScreen from "../Screens/InvestorScreens/InvestorLocationDetailsScreen";
import InvestorUserDetailsScreen from "../Screens/InvestorScreens/InvestorUserDetailsScreen";

import RWAPaymentsDetailsScreenRWA from "../Screens/RWASreens/RWAPaymentsDetailsScreen.js";
import LoginScreen from "../Screens/LoginScreen";
import axios from "axios";
import { BaseUrl } from "../static/BaseUrl";
import MonthlyElectricityBillingScreen from "../Screens/AdminScreens/MonthlyElectricityBillingScreen";
import SendPushNotification from "../Screens/AdminScreens/SendPushNotification";
import FAQScreen from "../Screens/AdminScreens/FAQScreen.js";
import ChargersLiveLogsScreen from "../Screens/AdminScreens/ChargersLiveLogsScreen.js";
import RWAPaymentsScreenForRwaAdmin from "../Screens/RWASreens/RWAPaymentsDetailsScreen.js";

const { Header, Sider, Content } = Layout;

const LayoutComponent = () => {
  const [selectedpage, setSelectedpage] = useState();
  const [AdminRole, setAdminRole] = useState();
  const [shownAlerts, setShownAlerts] = useState(false);
  const [alertModelOpen, setAlertModelOpen] = useState(false);

  const [unreadAlerts, setUnreadAlerts] = useState([]);
  const [alertsCount, setAlertsCount] = useState(0);

  const [pendingUserConcernCounts, setpendingUserConcernsCounts] = useState(0);

  const [newRfidRequests, setNewRfidRequests] = useState(0);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      setSelectedpage("/login");
    } else {
      getAdminDetails();
    }
  }, [localStorage.getItem("token")]);

  const getAlerts = async () => {
    try {
      const res = await axios.post(`${BaseUrl}/get/negative/balance/users`, {
        token: localStorage.getItem("token"),
      });
      if (res?.data?.success) {
        setUnreadAlerts(res?.data?.users);
        setAlertsCount(res?.data?.users?.length);
        setpendingUserConcernsCounts(res?.data?.pendingConcerns?.length);
        setNewRfidRequests(res?.data?.rfidRequests?.length);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAdminDetails = async () => {
    try {
      const res = await axios.post(`${BaseUrl}/get/admin/details`, {
        token: localStorage.getItem("token"),
      });
      if (res?.data?.success) {
        if (
          res?.data?.admin?.hasAllAccess &&
          res?.data?.admin?.role != "investor"
        ) {
          setAdminRole(AdminItems);
          getAlerts();
          setShownAlerts(true);
          setInterval(() => {
            getAlerts();
            // console.log("called");
          }, 1000 * 60);

          setSelectedpage("/");
        } else if (res?.data?.admin?.role === "investor") {
          setAdminRole(InvestorItems);
          setSelectedpage("/");
        } else {
          setAdminRole(RWAitems);
          setSelectedpage("/RWA");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  function getItem(label, key, children) {
    return {
      key,
      children,
      label,
    };
  }

  const RWAitems = [
    // getItem("Dashboard", "/comingSoon"),
    getItem("Dashboard", "/RWA"),
    getItem("Components", "sub1", [
      getItem("User Details", "/RWA/userDetails"),
    ]),
    getItem("Charging Sessions", "sub2", [
      getItem("Ongoing Bookings", "/RWA/ongoingBookings"),
      getItem("Monthly Electricity Billing", "/RWA/completedBookings"),
    ]),
    getItem("Payments Details", "/RWAPaymentsDetailHistory"),
  ];

  const InvestorItems = [
    getItem("Dashboard", "/"),
    getItem("Components", "sub1", [
      getItem("Location Details", "/InvestorlocationDetails"),
      getItem("Charger Details", "/InvestorchargerDetails"),
      getItem("User Details", "/InvestoruserDetails"),
      getItem("Completed Bookings", "/InvestorcompletedBookings"),
    ]),
  ];

  const AdminItems = [
    getItem("Dashboard", "/"),
    getItem("Chargers Statuses", "/allChargersPanel"),
    getItem("Components", "sub1", [
      getItem("Partner Queries", "/partnerQueries"),
      getItem("Customer Concerns", "/userConcerns"),
      getItem("Location Details", "/locationDetails"),
      getItem("Charger Details", "/chargerDetails"),
      getItem("User Details", "/userDetails"),
      getItem("Transactions", "/transactions"),
      getItem("Feedbacks", "/feedbacks"),
      getItem("FAQs", "/faqs"),
      getItem("Monthly Bookings Data", "/monthlyElectricityBilling"),
      getItem("RWA Payments Details", "/rwaPaymentsDetails"),

      getItem("RWA Admin Details", "/rwaAdminDetails"),
    ]),
    getItem("Charging Sessions", "sub2", [
      getItem("Ongoing Bookings", "/ongoingBookings"),
      getItem("Completed Bookings", "/completedBookings"),
    ]),
    getItem("RFID Card Management", "sub3", [
      getItem("New Requests", "/rfidCardsRequests"),
      getItem("RFID Cards", "/rfidCardsManagement"),
    ]),
    getItem("CMS Operations", "sub4", [
      getItem("Chargers Logs", "/liveLogs"),
      // getItem("Cancel Reservation", "/cms/cancel/reservation"),
      // getItem("Change Availability", "/cms/change/availability"),
      getItem("Get Configuration", "/cms/get/configuration"),
      getItem("Change Configuration", "/cms/change/configuration"),
      getItem("Clear Cache", "/cms/clear/cache"),
      // getItem("Set Charging Profile", "/cms/set/charging/profile"),
      // getItem("Clear Charging Profile", "/cms/clear/charging/profile"),
      // getItem("Get Diagnostics", "/cms/get/diagnostics"),
      getItem("Remote Start Transaction", "/cms/remote/start/transaction"),
      // getItem("Remote Stop Transaction", "/cms/remote/stop/transaction"),
      getItem("Update Local List", "/cms/send/locallist"),
      getItem("Get Local List Version", "/cms/get/locallist"),
      getItem("Reset", "/cms/reset"),
      getItem("Trigger Message", "/cms/trigger/message"),
      getItem("Update Firmware", "/cms/update/firmware"),
      getItem("Get Charging Logs", "/cms/download/logs"),
    ]),
    getItem("Send Notifications", "sub5", [
      getItem("Send Push Notifications", "/sendPushNotification"),
    ]),
    getItem("Electric Vehicles Data", "sub6", [
      getItem("Ev Manufacturers", "/evManufacturers"),
      getItem("Ev cars", "/evCars"),
    ]),
  ];

  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: "1",
      label: "Alerts",
      children: (
        <div style={{ height: 300, overflow: "scroll" }}>
          {unreadAlerts?.map((alert, index) => {
            return (
              <div
                className="shadow-xl"
                style={{
                  borderRadius: 10,
                  marginTop: 20,
                  borderWidth: 1,
                  borderColor: "#DCDCDC",
                }}
              >
                <a
                  onClick={() => {
                    setSelectedpage("/cms/send/locallist");
                    setAlertModelOpen(false);
                  }}
                  style={{ paddingLeft: 10, paddingRight: 10 }}
                >
                  <p style={{ paddingLeft: 14 }}>
                    {alert?.name} {alert?.phone} balance is in negative.
                  </p>
                </a>
              </div>
            );
          })}
        </div>
      ),
    },
  ];

  return selectedpage === "/login" ? (
    <LoginScreen />
  ) : (
    <Layout>
      <Modal
        centered
        open={alertModelOpen}
        onCancel={() => {
          setAlertModelOpen(false);
        }}
        footer={[null]}
      >
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </Modal>

      <Sider
        width={260}
        trigger={null}
        collapsible
        collapsedWidth={0}
        collapsed={collapsed}
      >
        <div
          className="demo-logo-vertical mt-2"
          style={{ overflow: "scroll" }}
        />
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={"/"}
          items={AdminRole}
          selectable={true}
          onSelect={(item) => {
            setSelectedpage(`${item?.key}`);
            window.scrollTo(0, 0);
          }}
        />
      </Sider>
      <Layout className="min-h-screen">
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
            width: "w-screen",
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
            }}
          />
          <span className="text-xl font-bold font-serif text-center pl-5">
            ElectricWeb
          </span>

          <a
            onClick={() => {
              setSelectedpage("/rfidCardsRequests");
            }}
            style={{
              position: "absolute",
              top: 10,
              right: 200,
            }}
          >
            <Badge size="small" count={newRfidRequests || 0}>
              <img
                style={{ width: 30, height: 27 }}
                src="./../static/rfid-chip.png"
              />
            </Badge>
          </a>

          <a
            // href="#"
            onClick={() => {
              setSelectedpage("/userConcerns");
            }}
            style={{
              position: "absolute",
              top: 5,
              right: 150,
            }}
          >
            <Badge size="small" count={pendingUserConcernCounts || 0}>
              <i
                style={{ fontSize: 23, marginTop: 3 }}
                class="fa-regular fa-comment-dots"
              ></i>
            </Badge>
          </a>

          <a
            href="#"
            onClick={() => {
              setAlertModelOpen(true);
            }}
            style={{
              position: "absolute",
              top: 5,
              right: 260,
            }}
          >
            <Badge size="small" count={alertsCount || 0}>
              <i
                style={{ fontSize: 22, marginTop: 3 }}
                class="fa-regular fa-bell"
              ></i>
            </Badge>
          </a>

          <a
            onClick={() => {
              localStorage.clear();
              setSelectedpage("/login");
            }}
            className="pr-3 pl-3 text-sm font-serif mt-4 pt-2 pb-2 text-white "
            style={{
              position: "absolute",
              right: 50,
              borderRadius: 10,
              backgroundColor: "#001529",
            }}
          >
            Logout
          </a>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            background: colorBgContainer,
            className: "overflow-auto",
          }}
        >
          {selectedpage === "/" ? (
            <DashBoardScreen />
          ) : selectedpage === "/locationDetails" ? (
            <LocationDetailsScreen />
          ) : selectedpage === "/chargerDetails" ? (
            <ChargerDetailsScreen />
          ) : selectedpage === "/userDetails" ? (
            <UserDetailsScreen />
          ) : selectedpage === "/userConcerns" ? (
            <UsersConcernsScreen />
          ) : selectedpage === "/liveLogs" ? (
            <ChargersLiveLogsScreen />
          ) : selectedpage === "/rfidCardsManagement" ? (
            <RFIDCardsScreen />
          ) : selectedpage === "/rfidCardsRequests" ? (
            <RFIDCardsNewRequests />
          ) : selectedpage === "/ongoingBookings" ? (
            <OngoingBookingsScreen />
          ) : selectedpage === "/completedBookings" ? (
            <CompletedBookingsScreen />
          ) : selectedpage === "/transactions" ? (
            <TransactionDetailsScreen />
          ) : selectedpage === "/allChargersPanel" ? (
            <AllChargersScreen />
          ) : selectedpage === "/feedbacks" ? (
            <FeedbackScreen />
          ) : selectedpage === "/faqs" ? (
            <FAQScreen />
          ) : selectedpage === "/rwaAdminDetails" ? (
            <RWAadminDetails />
          ) : selectedpage === "/monthlyElectricityBilling" ? (
            <MonthlyElectricityBillingScreen />
          ) : selectedpage === "/rwaPaymentsDetails" ? (
            <RWAPaymentsDetailsScreen />
          ) : // RWA Screens
          selectedpage === "/RWA" ? (
            <RWADashboardScreen />
          ) : selectedpage === "/RWA/userDetails" ? (
            <RWAUsersDetails />
          ) : selectedpage === "/RWA/userDetails" ? (
            <RWAPaymentsDetailsScreenRWA />
          ) : selectedpage === "/RWA/ongoingBookings" ? (
            <RWAOngoingBookingsScreen />
          ) : selectedpage === "/RWA/completedBookings" ? (
            <RWACompletedBookingsScreen />
          ) : selectedpage === "/RWAPaymentsDetailHistory" ? (
            <RWAPaymentsScreenForRwaAdmin />
          ) : // CMS Operations
          selectedpage === "/cms/reserve/now" ? (
            <ReserveNowScreen />
          ) : selectedpage === "/cms/cancel/reservation" ? (
            <CancelReservationScreen />
          ) : selectedpage === "/cms/change/availability" ? (
            <ChangeAvailabilityScreen />
          ) : selectedpage === "/cms/get/configuration" ? (
            <GetConfigurationScreen />
          ) : selectedpage === "/cms/change/configuration" ? (
            <ChangeConfigurationScreen />
          ) : selectedpage === "/cms/clear/cache" ? (
            <ClearCacheScreen />
          ) : selectedpage === "/cms/set/charging/profile" ? (
            <SetChargingProfileScreen />
          ) : selectedpage === "/cms/clear/charging/profile" ? (
            <ClearChargingProfile />
          ) : selectedpage === "/cms/get/diagnostics" ? (
            <GetDiagnosticsScreen />
          ) : selectedpage === "/partnerQueries" ? (
            <PartnerQueryScreen />
          ) : selectedpage === "/cms/remote/start/transaction" ? (
            <RemoteStartScreen />
          ) : selectedpage === "/cms/remote/stop/transaction" ? (
            <RemoteStopScreen />
          ) : selectedpage === "/cms/send/locallist" ? (
            <SendLocalListScreen />
          ) : selectedpage === "/cms/get/locallist" ? (
            <GetLocalListScreen />
          ) : selectedpage === "/cms/reset" ? (
            <ResetChargerScreen />
          ) : selectedpage === "/cms/download/logs" ? (
            <DownLoadLogsScreen />
          ) : selectedpage === "/cms/trigger/message" ? (
            <TriggerMessageScreen />
          ) : selectedpage === "/cms/update/firmware" ? (
            <UpdateFirmwareScreen />
          ) : selectedpage === "/cms/unlock/connector" ? (
            <UnlockConnectorScreen />
          ) : selectedpage === "/sendPushNotification" ? (
            <SendPushNotification />
          ) : selectedpage === "/evCars" ? (
            <EvCarsScreen />
          ) : selectedpage === "/evManufacturers" ? (
            <EvManufacturersScreen />
          ) : selectedpage === "/InvestorchargerDetails" ? (
            <InvestorChargerDetailsScreen />
          ) : selectedpage === "/InvestorcompletedBookings" ? (
            <InvestorCompletedBookingsScreen />
          ) : selectedpage === "/InvestorlocationDetails" ? (
            <InvestorLocationDetailsScreen />
          ) : selectedpage === "/InvestoruserDetails" ? (
            <InvestorUserDetailsScreen />
          ) : (
            ""
          )}
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutComponent;
